import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { Button, Grid } from 'antd';

import { CookieBannerType } from 'types/cookieBanner';
import analyticsAdapter from 'utils/analyticsAdapter';

import { Container, Title, Description } from './styles';

export type CookieBannerProps = {
  cookieBanner: CookieBannerType;
};

const CookieBanner = ({ cookieBanner }: CookieBannerProps) => {
  const [shouldShow, setShouldShow] = useState(false);
  const screens = Grid.useBreakpoint();

  useEffect(() => {
    import('utils/cookieBannerStorage').then(({ default: storage }) => {
      const { cookiesSeen = false } = storage.getData() || {};
      setShouldShow(!cookiesSeen);
    });
  }, []);

  if (!cookieBanner || !shouldShow) {
    return null;
  }

  const eatCookies = (enable) => {
    import('utils/cookieBannerStorage').then(({ default: storage }) => {
      storage.enableCookies(enable);
      storage.setCookiesSeen();
      setShouldShow(false);

      if (enable) {
        analyticsAdapter.initialize();
        analyticsAdapter.trackEvent({
          category: 'Cookies',
          action: 'Click - OK',
          label: 'Cookie Banner',
        });
      }
    });
  };

  const isMobile = !screens.md;

  return (
    <Container $isMobile={isMobile}>
      <Title level={5}>{cookieBanner.copy.title}</Title>
      <Description>
        {cookieBanner.copy.content}
        {/* We use cookies on our site to enhance your user experience, provide
        personalised content, and analyse our site traffic. By clicking
        &apos;Accept&apos;, you consent to the use of all cookies. To learn more
        about how we use cookies, please see our{' '}
        <Link href="/legal/privacy-policy">Privacy Policy</Link>. */}
      </Description>
      <Button
        type="primary"
        onClick={() => eatCookies(true)}
        style={{ minWidth: 120, marginRight: 10 }}
      >
        {cookieBanner.copy.agree}
      </Button>
      <Button onClick={() => eatCookies(false)} type="text">
        {cookieBanner.copy.decline}
      </Button>
    </Container>
  );
};

export default CookieBanner;
