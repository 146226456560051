import { useRef } from 'react';
import Link from 'next/link';
import {
  Typography,
  Button,
  Row,
  Col,
  Space,
  Grid,
  Divider,
  Tooltip,
  Badge,
} from 'antd';
import { LogoutOutlined, WarningFilled } from '@ant-design/icons';
import { signOut } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import Image from 'next/image';
import { useInView } from 'framer-motion';

import Notifications from 'components/Notifications';

import useUserData from 'hooks/useUserData';

import { annualFeePaid } from 'utils/oppHelpers';

import theme from 'styles/theme';
import { HeaderContainer, MainLink, MainLinkNoHref } from './styles';

import logo from '../../../public/images/logo.png';

function Header() {
  const { data: session } = useSession();
  const screens = Grid.useBreakpoint();
  const router = useRouter();
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { amount: 0.5, once: true });

  const { userData } = useUserData();

  const isDashboard = router.route.includes('dashboard');
  const isApply = router.route.includes('apply');
  const isAccount = router.route.includes('account');
  const isSyndicate = router.route.includes('syndicate');
  const isPortfolio = router.route.includes('portfolio');
  const isAdmin = router.route.includes('admin');
  const isSignIn = router.route.includes('login');
  const isRegister = router.route.includes('register');
  const is2fa = router.route.includes('2fa');
  const isOnboarding = router.route.includes('onboarding');
  const isConfirmEmail = router.route.includes('confirm-email');
  const isAuthFlow =
    isSignIn || is2fa || isOnboarding || isRegister || isConfirmEmail;

  const feesPaid = annualFeePaid(userData?.OPP?.annualFee);
  const kycApproved = userData?.OPP?.kycApproved;
  const showBadge = !feesPaid || !kycApproved;

  return (
    <HeaderContainer ref={containerRef} $isInView={isInView}>
      <Row
        style={{
          padding: screens.md ? '30px 60px 10px' : '15px 30px 5px',
          position: 'relative',
          maxWidth: 1200,
          margin: '0 auto',
        }}
        align="middle"
        justify="space-between"
      >
        <Col sm={12} md={session ? 24 : 12}>
          <Link
            href={session ? '/dashboard' : '/'}
            style={{
              width: screens.md ? 150 : 100,
              marginBottom: session && !isAuthFlow ? 30 : isApply ? 20 : 0,
              display: 'flex',
            }}
          >
            <Image
              src={logo}
              loading="eager"
              alt="Logo"
              style={{ width: '100%', height: 'auto' }}
            />
          </Link>
        </Col>
        {session && !isAuthFlow && (
          <Col sm={12} md={12}>
            <Row align="middle" justify="start">
              <Space size={25}>
                <MainLink href="/dashboard" $isActive={isDashboard}>
                  Dashboard
                </MainLink>
                {session.user.persona === 'investor' && (
                  <>
                    <MainLink href="/portfolio" $isActive={isPortfolio}>
                      Portfolio
                    </MainLink>
                    <MainLink href="/syndicate" $isActive={isSyndicate}>
                      Syndicate
                    </MainLink>
                  </>
                )}
                {/* {session.user.persona === 'investor' && (
                  <MainLink href="/resources" $isActive={isResources}>
                    Resources
                  </MainLink>
                )} */}
              </Space>
            </Row>
          </Col>
        )}
        <Col sm={12} md={12}>
          <Row justify="end" align="middle">
            {session && !isAuthFlow && (
              <Space size={25}>
                {userData?.role?.name === 'Super Admin' && (
                  <MainLink href="/admin" $isActive={isAdmin}>
                    Admin
                  </MainLink>
                )}
                <Badge
                  dot={showBadge}
                  offset={[4, 2]}
                  style={{
                    fontWeight: 700,
                    fontFamily: 'Gilroy',
                  }}
                  size="small"
                >
                  <MainLink href="/account" $isActive={isAccount}>
                    Account
                  </MainLink>
                </Badge>
                <MainLinkNoHref
                  onClick={() => {
                    signOut({
                      callbackUrl: `${process.env.NEXTAUTH_URL}`,
                    });
                  }}
                >
                  Sign out
                </MainLinkNoHref>
                {/* <Tooltip title="Sign out">
                  <LogoutOutlined
                    style={{
                      fontSize: 24,
                      color: theme.colors.primaryDeep,
                    }}
                    onClick={() => {
                      signOut({
                        callbackUrl: `${process.env.NEXTAUTH_URL}`,
                      });
                    }}
                  />
                </Tooltip> */}
                <Notifications />
                {/* <Link href="/dashboard">
                  <UserAvatar size={30} src={userData?.avatar?.url} />
                </Link> */}
              </Space>
            )}
            {!session && !isApply && (
              // <Link
              //   href={isSignIn ? '/auth/register' : '/auth/login'}
              //   style={{ fontWeight: 700, fontFamily: 'Gilroy' }}
              // >
              //   <Button>{isSignIn ? 'Register' : 'Sign in'}</Button>
              // </Link>
              <Link
                href="/apply"
                style={{ fontWeight: 700, fontFamily: 'Gilroy' }}
              >
                <Button>Apply for funding</Button>
              </Link>
            )}
          </Row>
        </Col>
        {session && !isAuthFlow && (
          <Divider
            style={{
              margin: screens.md ? '20px 0 0' : '5px -30px 0',
              width: screens.md ? '100%' : 'calc(100% + 60px)',
            }}
          />
        )}
      </Row>
    </HeaderContainer>
  );
}

export default Header;
