import styled from 'styled-components';
import { Typography } from 'antd';

interface ContainerProps {
  $isMobile: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: ${(props) => (props.$isMobile ? 'calc(100vw - 40px)' : '600px')};
  padding: 15px 20px 20px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  z-index: 1000001;
`;

export const Title = styled(Typography.Title)`
  &&& {
    margin-bottom: 5px;
  }
`;

export const Description = styled(Typography.Paragraph)`
  &&& {
    margin-bottom: 15px;
    font-size: 14px;
  }
`;
