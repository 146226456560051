import { gql } from '@apollo/client';

export const resourceFragment = gql`
  fragment ResourceFields on Resource {
    createdAt
    title
    slug
    author
    authorAvatar {
      data {
        attributes {
          url
          width
          height
        }
      }
    }
    description
    heroImage {
      data {
        attributes {
          url
          width
          height
        }
      }
    }
    url
    linkType
    showOnHomepage
  }
`;

export const GET_RESOURCES_QUERY = gql`
  query getResources(
    $start: Int
    $limit: Int
    $sort: [String]
    $where: ResourceFiltersInput
  ) {
    resources(
      pagination: { start: $start, limit: $limit }
      sort: $sort
      filters: $where
    ) {
      data {
        id
        attributes {
          ...ResourceFields
        }
      }
      meta {
        pagination {
          page
          pageSize
          total
          pageCount
        }
      }
    }
  }
  ${resourceFragment}
`;

export const GET_HOMEPAGE_RESOURCES_QUERY = gql`
  {
    resources(
      sort: "createdAt:asc"
      pagination: { start: 0, limit: 3 }
      filters: { showOnHomepage: { eq: true } }
    ) {
      data {
        id
        attributes {
          ...ResourceFields
        }
      }
    }
  }
  ${resourceFragment}
`;

export const GET_RESOURCE_SLUGS_QUERY = gql`
  query getResources(
    $start: Int
    $limit: Int
    $sort: [String]
    $where: ResourceFiltersInput
  ) {
    resources(
      pagination: { start: $start, limit: $limit }
      sort: $sort
      filters: $where
    ) {
      slug
    }
  }
`;

export const GET_RESOURCE_BY_SLUG_QUERY = gql`
  query getResource($slug: String!) {
    resourceBySlug(slug: $slug) {
      data {
        id
        attributes {
          ...ResourceFields
          content
          keywords
          heroImage {
            data {
              attributes {
                url
                width
                height
              }
            }
          }
        }
      }
    }
  }
  ${resourceFragment}
`;
