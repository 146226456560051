import styled from 'styled-components';
import { Typography } from 'antd';
import { timingFunctions } from 'polished';
import Link from 'next/link';

import theme from 'styles/theme';

interface HeaderContainerProps {
  readonly $isInView: boolean;
}

export const HeaderContainer = styled.div<HeaderContainerProps>`
  opacity: ${(props) => (props.$isInView ? 1 : 0)};
  transform: ${(props) =>
    props.$isInView ? 'translateY(0px)' : 'translateY(-25px)'};

  transition-property: opacity, transform;
  transition-duration: 0.5s;
  transition-timing-function: ${timingFunctions('easeOutQuad')};
  transition-delay: 1s;
`;

interface MainLinkProps {
  readonly $isActive: boolean;
}

export const MainLink = styled(Link)<MainLinkProps>`
  font-family: 'Gilroy';
  font-weight: 700;
  padding-bottom: 24px;
  color: ${(props) => props.$isActive && theme.colors.primaryDeep};
  border-bottom: ${(props) =>
    props.$isActive && `2px solid ${theme.colors.primaryDeep}`};
  pointer-events: ${(props) => (props.$isActive ? 'none' : 'auto')};
`;

export const MainLinkNoHref = styled(Typography.Link)`
  &&& {
    font-family: 'Gilroy';
    font-weight: 700;
    padding-bottom: 24px;
  }
`;
