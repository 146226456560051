import Image from 'next/image';
import Link from 'next/link';
import { useSession } from 'next-auth/react';
import { Col, Grid, Space, Typography } from 'antd';

import theme from 'styles/theme';
import {
  FooterContainer,
  Wrapper,
  Border,
  LinksRow,
  FooterLink,
  BuildVersion,
} from './styles';

import pIcon from 'assets/images/logo-short.png';

function Footer() {
  const { data: session } = useSession();
  const screens = Grid.useBreakpoint();

  const isMobile = !screens.md;

  return (
    <FooterContainer $isMobile={isMobile}>
      <Wrapper $isMobile={isMobile}>
        <LinksRow justify="start">
          <Col
            flex={screens.md ? '34%' : '100%'}
            style={{
              marginBottom: screens.md ? 0 : 30,
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <Image src={pIcon} alt="" width={40} />
            <BuildVersion>{process.env.GITHUB_REF_NAME}</BuildVersion>
          </Col>
          <Col
            flex={screens.md ? '22%' : '50%'}
            style={{ marginBottom: screens.md ? 0 : 30 }}
          >
            <Typography.Title level={5} style={{ color: theme.colors.primary }}>
              General
            </Typography.Title>
            <Space direction="vertical" size={10}>
              <Link href="/help/code-of-conduct" passHref legacyBehavior>
                <FooterLink type="secondary">Code of Conduct</FooterLink>
              </Link>
              {/* <Link href="/help/faq?activeTab=investor" passHref legacyBehavior>
                <FooterLink type="secondary">Investor FAQ</FooterLink>
              </Link>
              <Link href="/help/faq?activeTab=founder" passHref legacyBehavior>
                <FooterLink type="secondary">Founder FAQ</FooterLink>
              </Link> */}
              <FooterLink
                href="mailto:support@pitchedit.co"
                target="_blank"
                rel="noreferrer"
                type="secondary"
              >
                Support
              </FooterLink>
              {session?.user && (
                <Link href="/auth/data-removal" passHref legacyBehavior>
                  <Typography.Text type="secondary">
                    Data Removal
                  </Typography.Text>
                </Link>
              )}
            </Space>
          </Col>
          <Col
            flex={screens.md ? '22%' : '50%'}
            style={{ marginBottom: screens.md ? 0 : 30 }}
          >
            <Typography.Title level={5} style={{ color: theme.colors.primary }}>
              Legal
            </Typography.Title>
            <Space direction="vertical" size={10}>
              <Link href="/legal/risk-warning" passHref legacyBehavior>
                <FooterLink type="secondary">Risk Warning</FooterLink>
              </Link>
              {/* <Link href="/legal/investee-terms" passHref legacyBehavior>
                <FooterLink type="secondary">Investee Terms</FooterLink>
              </Link>
              <Link href="/legal/terms-of-use" passHref legacyBehavior>
                <FooterLink type="secondary">Terms of Use</FooterLink>
              </Link> */}
              <Link href="/legal/privacy-policy" passHref legacyBehavior>
                <FooterLink type="secondary">Privacy Policy</FooterLink>
              </Link>
              <Link href="/legal/complaints" passHref legacyBehavior>
                <FooterLink type="secondary">Complaints</FooterLink>
              </Link>
            </Space>
          </Col>
          <Col flex={screens.md ? '22%' : '100%'} style={{ marginBottom: 0 }}>
            <Typography.Title level={5} style={{ color: theme.colors.primary }}>
              Social
            </Typography.Title>
            <Space direction="vertical" size={10}>
              <FooterLink
                href="https://www.linkedin.com/company/pitchedit"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </FooterLink>
              <FooterLink
                href="https://www.instagram.com/pitchedit.co"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </FooterLink>
              <FooterLink
                href="https://twitter.com/pitcheditco"
                target="_blank"
                rel="noreferrer"
              >
                Twitter
              </FooterLink>
            </Space>
          </Col>
        </LinksRow>
      </Wrapper>
      <Border />
      <Wrapper $isMobile={isMobile}>
        <LinksRow>
          <Typography.Title level={5} style={{ color: theme.colors.primary }}>
            Risk Warning
          </Typography.Title>
          <Typography.Paragraph
            style={{
              fontSize: 14,
              lineHeight: '22px',
              textAlign: 'justify',
              color: theme.colors.textSecondary,
            }}
          >
            All forms of investments carry a degree of risk, particularly those
            involving start-up companies. Such investments are at risk of
            illiquidity, lack of dividends, loss of investment and dilution and
            should only be made as part of a diversified portfolio. PitchedIt is
            targeted at investors who have sufficient understanding of these
            risks. Only sufficiently sophisticated investors will be permitted
            to invest through our platform, or those who meet the criteria
            through our risk-based assessment. Pitches on the PitchedIt platform
            are not offers made to the public. Investments can only be made by
            authorised PitchedIt members on the basis of information provided in
            the pitches by the companies concerned. Please read our full{' '}
            <Link
              href="/legal/risk-warning"
              style={{ color: theme.colors.primary }}
            >
              risk warning
            </Link>{' '}
            for more details.
          </Typography.Paragraph>
        </LinksRow>
      </Wrapper>
    </FooterContainer>
  );
}

export default Footer;
