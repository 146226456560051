import { gql } from '@apollo/client';

export const GET_WAITLIST_SUBMISSION = gql`
  query getWaitlistSubmission($id: ID!) {
    waitlistSubmission(id: $id) {
      data {
        id
        attributes {
          createdAt
          email
          investmentSignalled
          hash
          referrals {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_WAITLIST_SUBMISSION_BY_EMAIL_QUERY = gql`
  query getWaitlistSubmissionByEmail($email: String!) {
    getWaitlistSubmissionByEmail(email: $email) {
      data {
        id
        attributes {
          createdAt
          email
          investmentSignalled
          hash
          referrals {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_WAITLIST_POSITION_QUERY = gql`
  query getWaitlistPosition($createdAt: DateTime!) {
    waitlistPosition: waitlistSubmissions(
      filters: { createdAt: { lt: $createdAt } }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
  }
`;

export const GET_WAITLIST_SUM_QUERY = gql`
  query getWaitlistSum {
    waitlistSum: getWaitlistSum
  }
`;

export const JOIN_WAITLIST_QUERY = gql`
  mutation createWaitlistSubmission(
    $email: String!
    $investmentSignalled: Int!
    $hash: String
  ) {
    createWaitlistSubmission(
      data: {
        email: $email
        investmentSignalled: $investmentSignalled
        hash: $hash
      }
    ) {
      data {
        id
        attributes {
          investmentSignalled
          hash
        }
      }
    }
  }
`;

export const LINK_INVITE_CODE_QUERY = gql`
  mutation linkInviteCode($id: ID!, $hash: String!) {
    linkInviteCode: linkWaitlistSubmissionReferral(id: $id, hash: $hash) {
      ok
      error
    }
  }
`;
