import { useQuery } from '@apollo/client';
import { useSession } from 'next-auth/react';

import { UserProps } from 'types';

import { USER_DATA_QUERY } from 'queries';

const useUserData = () => {
  const { data: session } = useSession();
  const { data, loading, error } = useQuery<{ me: UserProps }>(
    USER_DATA_QUERY,
    {
      skip: !session,
      variables: { id: session?.user.id },
    }
  );

  const userData: UserProps = data?.me;

  return { userData, loading, error };
};

export default useUserData;
