import styled from 'styled-components';
import { Row, Typography } from 'antd';

import theme from 'styles/theme';

export const FooterContainer = styled.div<{
  $isMobile?: boolean;
}>`
  background-color: ${theme.colors.primaryDeeper};
  padding: ${(props) => (props.$isMobile ? '0px 0 10px' : '50px 0 10px')};
`;

export const Wrapper = styled.div<{
  $isMobile?: boolean;
}>`
  width: 100%;
  padding: ${(props) => (props.$isMobile ? '40px 30px' : '40px 60px')};
  max-width: 1200px;
  margin: 0 auto;
`;

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.primarySofter}30;
`;

export const FooterLink = styled(Typography.Link)`
  &&& {
    color: ${theme.colors.primarySoft};
    font-size: 14px;

    &:hover {
      color: ${theme.colors.primary};
    }
  }
`;

export const SocialLink = styled.a`
  font-size: 26px;
`;

export const LinksRow = styled(Row)`
  margin: 0 auto;
`;

export const BuildVersion = styled(Typography.Text)`
  &&& {
    margin: 0;
    color: ${theme.colors.primary};
    font-size: 14px;
  }
`;
