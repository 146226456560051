import { gql } from '@apollo/client';

export const getInvestmentFragment = gql`
  fragment GetInvestmentFields on Investment {
    upcomingInvestment {
      data {
        id
        attributes {
          title
          description
          status
          deadline
        }
      }
    }
    dateCreated
    status
    hasReflectionPeriod
    amount
    investments {
      id
      assetClass
      project {
        data {
          id
          attributes {
            OPP {
              merchantId
              profileId
            }
            founderProfiles {
              name
              position
              linkedInUrl
              avatar {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            name
            slug
            description
            fundingTarget
            fundingCommitted
            distributionModel
            incorporationCountry
            leadInvestor
            leadInvestorUrl
            preMoneyValuation
            issuedShares
            verticals {
              data {
                id
                attributes {
                  name
                }
              }
            }
          }
        }
      }
      amount
      type
      sharePrice
      sharesAcquired
      chargeId
    }
  }
`;

export const GET_INVESTMENTS_QUERY = gql`
  ${getInvestmentFragment}
  query getInvestments(
    $filters: InvestmentFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    investments(pagination: $pagination, filters: $filters, sort: $sort) {
      data {
        id
        attributes {
          ...GetInvestmentFields
        }
      }
      meta {
        pagination {
          page
          pageSize
          total
          pageCount
        }
      }
    }
  }
`;

export const GET_INVESTMENTS_COUNT_AND_SUM_QUERY = gql`
  query getInvestmentsCountAndSum($userId: ID) {
    getInvestmentsCountAndSum(userId: $userId) {
      count
      sum
    }
  }
`;

export const GET_SYNDICATE_QTRLY_AND_INVEST_SUM_QUERY = gql`
  query getSyndicateQuarterlySumAndInvestmentSum($syndicateLead: ID) {
    getSyndicateQuarterlySumAndInvestmentSum(syndicateLead: $syndicateLead) {
      investmentSum
      quarterlySum
    }
  }
`;

export const GET_INVESTMENT_QUERY = gql`
  ${getInvestmentFragment}
  query getInvestment($id: ID) {
    investment(id: $id) {
      data {
        id
        attributes {
          ...GetInvestmentFields
        }
      }
    }
  }
`;

export const CREATE_INVESTMENT_QUERY = gql`
  ${getInvestmentFragment}
  mutation createInvestment(
    $user: ID!
    $upcomingInvestment: ID!
    $dateCreated: DateTime!
    $status: ENUM_INVESTMENT_STATUS!
    $hasReflectionPeriod: Boolean!
    $investments: [ComponentInvestmentItemInput]!
    $amount: Int!
  ) {
    createInvestment(
      data: {
        user: $user
        upcomingInvestment: $upcomingInvestment
        dateCreated: $dateCreated
        status: $status
        hasReflectionPeriod: $hasReflectionPeriod
        investments: $investments
        amount: $amount
      }
    ) {
      data {
        id
        attributes {
          ...GetInvestmentFields
        }
      }
    }
  }
`;

export const UPDATE_INVESTMENT_QUERY = gql`
  ${getInvestmentFragment}
  mutation updateInvestment($id: ID!, $status: ENUM_INVESTMENT_STATUS!) {
    updateInvestment(id: $id, data: { status: $status }) {
      data {
        id
        attributes {
          ...GetInvestmentFields
        }
      }
    }
  }
`;
