import { useMemo, useState, useEffect, useRef } from 'react';
import {
  Tooltip,
  Row,
  Badge,
  Drawer,
  List,
  Typography,
  Spin,
  Grid,
  Divider,
} from 'antd';
import { PlusCircleFilled, BellFilled } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { useSession } from 'next-auth/react';
import * as R from 'ramda';
import InfiniteScroll from 'react-infinite-scroll-component';
import { motion, AnimatePresence } from 'framer-motion';

import { NotificationProps } from 'types/notification';

import { NOTIFICATIONS_QUERY, UPDATE_NOTIFICATIONS_QUERY } from 'queries';

import Notification from 'components/Notification';

import { flattenEntities } from 'utils/graphql';

import theme from 'styles/theme';

const LIMIT = 20;

function Notifications() {
  const screens = Grid.useBreakpoint();
  const bellRef = useRef(null);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const { data: session } = useSession();

  const { data, fetchMore } = useQuery(NOTIFICATIONS_QUERY, {
    skip: !session,
    variables: {
      pagination: {
        start: 0,
        limit: LIMIT,
      },
      filters: {
        receiver: { id: { eq: session?.user.id } },
      },
    },
  });
  const notifications: NotificationProps[] = useMemo(() => {
    return flattenEntities(data?.notifications)?.data || [];
  }, [data?.notifications]);
  const notificationsCount: number = useMemo(() => {
    return flattenEntities(data?.notifications)?.pagination?.total || [];
  }, [data?.notifications]);

  const [updateNotifications] = useMutation(UPDATE_NOTIFICATIONS_QUERY, {
    update(cache, { data: { markNotificationsSeen } }) {
      if (markNotificationsSeen.ok) {
        cache.writeQuery({
          query: NOTIFICATIONS_QUERY,
          variables: {
            pagination: {
              start: 0,
              limit: LIMIT,
            },
            filters: {
              receiver: { id: { eq: session?.user.id } },
            },
          },
          data: {
            notifications: R.map(R.assoc('status', 'seen'), notifications),
          },
        });
      }
    },
    onCompleted() {
      // let link: HTMLLinkElement = document.querySelector("link[rel~='icon']");
      // if (!link) {
      //   link = document.createElement('link');
      //   link.rel = 'icon';
      //   document.getElementsByTagName('head')[0].appendChild(link);
      // }
      // link.href = '/favicon.ico';
    },
  });

  // useEffect(() => {
  //   const filteredNotifications = R.filter(
  //     R.where({ status: R.equals('seen') })
  //   )(notifications);
  //   const count = R.length(filteredNotifications);
  //   setNotificationCount(count);

  //   if (count > 0) {
  //     let link: HTMLLinkElement = document.querySelector("link[rel~='icon']");
  //     if (!link) {
  //       link = document.createElement('link');
  //       link.rel = 'icon';
  //       document.getElementsByTagName('head')[0].appendChild(link);
  //     }
  //     link.href = '/favicon-alert.ico';
  //   }
  // }, [notifications]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        !event.target.closest('.ant-drawer-content-wrapper') &&
        !event.target.closest('.Notification-ErrorButton') &&
        !event.target.closest('.Notification-ReadMoreButton') &&
        !bellRef.current?.contains(event.target)
      ) {
        setNotificationsOpen(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [bellRef]);

  const loadMoreData = () => {
    fetchMore({
      variables: {
        start: notifications.length,
        limit: LIMIT,
        where: {
          user: session?.user.id,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        return {
          notificationsCount: fetchMoreResult.notificationsCount,
          notifications: [
            ...previousResult.notifications,
            ...fetchMoreResult.notifications,
          ],
        };
      },
    });
  };

  const lastSeenIndex = R.findLastIndex(R.where({ status: R.equals('seen') }))(
    notifications
  );

  const popoverContent = (
    <>
      <Row
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px',
        }}
      >
        <Typography.Title level={3} style={{ marginBottom: 0 }}>
          Notifications
        </Typography.Title>
        <PlusCircleFilled
          onClick={() => setNotificationsOpen(false)}
          style={{
            fontSize: 28,
            transform: 'rotate(45deg)',
            color: theme.colors.primaryDeep,
          }}
        />
      </Row>
      <Divider style={{ margin: 0 }} />
      <div
        id="scrollableDiv"
        style={{ height: 'calc(100vh - 78px', overflow: 'auto' }}
      >
        <InfiniteScroll
          scrollableTarget="scrollableDiv"
          dataLength={notifications.length}
          next={loadMoreData}
          hasChildren={true}
          hasMore={notifications.length < notificationsCount}
          loader={
            <Spin
              style={{ display: 'block', margin: '20px auto' }}
              size="small"
            />
          }
          endMessage={null}
        >
          <List>
            <AnimatePresence>
              {notifications.map((item: NotificationProps) => (
                <motion.div
                  key={item.id}
                  layout
                  initial={{ opacity: 1, height: 'auto' }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{
                    opacity: 0,
                    height: 0,
                    overflow: 'hidden',
                    transition: { duration: 0.25 },
                  }}
                >
                  <Notification
                    notification={item}
                    limit={LIMIT}
                    setNotificationsOpen={setNotificationsOpen}
                  />
                </motion.div>
              ))}
            </AnimatePresence>
          </List>
        </InfiniteScroll>
      </div>
    </>
  );

  return (
    <>
      <Drawer
        rootClassName="Notification-Drawer"
        open={notificationsOpen}
        placement="right"
        closable={false}
        width={screens.md ? '450px' : '100%'}
        styles={{ body: { padding: 0 } }}
        destroyOnClose
        // onClose={() => {
        //   if (lastSeenIndex >= 0) updateNotifications();
        // }}
      >
        {popoverContent}
      </Drawer>
      <Tooltip title="Notifications">
        <Badge
          count={notificationsCount}
          offset={[0, -1]}
          style={{
            backgroundColor: theme.colors.secondary,
            fontSize: 10,
            fontWeight: 700,
            fontFamily: 'Gilroy',
          }}
          size="small"
        >
          <BellFilled
            ref={bellRef}
            onClick={() => setNotificationsOpen(!notificationsOpen)}
            style={{
              fontSize: 24,
              color: theme.colors.primaryDeep,
            }}
          />
        </Badge>
      </Tooltip>
    </>
  );
}

export default Notifications;
