import ReactGA from 'react-ga4';

export type AnalyticsAdapterDataType = {
  category: string;
  action: string;
  label?: string;
};

class AnalyticsAdapter {
  private isProduction: boolean;
  private isInitialized: boolean;

  constructor() {
    this.isProduction = process.env.NODE_ENV === 'production';
  }

  initialize() {
    import('utils/cookieBannerStorage').then(({ default: storage }) => {
      const { cookiesEnabled } = storage.getData();

      if (cookiesEnabled && !this.isInitialized) {
        this.isInitialized = true;

        ReactGA.set({ anonymizeIp: true });
        ReactGA.initialize(process.env.GA_ID);

        console.log(
          '---[ Google Analytics 4 activated ]---',
          process.env.GA_ID
        );
      }
    });
  }

  trackEvent(
    data: AnalyticsAdapterDataType = { category: '', action: '', label: '' }
  ) {
    import('utils/cookieBannerStorage').then(({ default: storage }) => {
      const { cookiesEnabled } = storage.getData();

      if (cookiesEnabled && this.isInitialized) {
        ReactGA.event(data);
      }
    });
  }
}

const GoogleAnalyticsAdapter = new AnalyticsAdapter();

export default GoogleAnalyticsAdapter;
