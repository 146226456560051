const theme = {
  colors: {
    primary: '#6747C4',
    primaryDeep: '#321863',
    primaryDeeper: '#0c0220',
    primaryHalf: '#703fcb20',
    primarySoft: '#e3d7ff',
    primarySofter: '#f7f4ff',
    primarySoftest: '#FCFAFF',
    secondary: '#ff5959',
    secondarySoft: '#FFF8F5',
    accent: '#f5958a',
    text: '#1C2541',
    textSecondary: '#b8b8b8',
    buttonBlue: '#320C7A',
    green: '#64d97f',
    white: '#ffffff',
    black: '#000000',
  },
  font: {
    size: {
      headingOne: {
        smallest: '32px',
        small: '35px',
        large: '48px',
        largest: '60px',
      },
      headingTwo: {
        small: '24px',
        large: '34px',
      },
      headingThree: {
        small: '20px',
        large: '24px',
      },
      headingFour: {
        small: '16px',
        large: '20px',
      },
      paragraph: {
        small: '14px',
        regular: '16px',
        large: '18px',
      },
    },
  },
  size: {
    maxWidth: '1200px',
  },
  shadows: {
    card: '0px 5px 8px 0px #00000010',
  },
};

export default theme;
