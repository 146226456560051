import { gql } from '@apollo/client';

export const getUpcomingInvestmentFragment = gql`
  fragment GetUpcomingInvestmentFields on UpcomingInvestment {
    title
    description
    openingDate
    deadline
    status
    selectedInvestments {
      memo
      assetClass
      KIIS {
        data {
          attributes {
            url
          }
        }
      }
      project {
        data {
          id
          attributes {
            OPP {
              merchantId
              profileId
            }
            founderProfiles {
              name
              position
              linkedInUrl
              avatar {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            name
            slug
            url
            description
            problem
            solution
            businessModel
            longTermAmbition
            fundingTarget
            fundingCommitted
            distributionModel
            incorporationCountry
            leadInvestor
            leadInvestorUrl
            preMoneyValuation
            issuedShares
            verticals {
              data {
                id
                attributes {
                  name
                }
              }
            }
            pitchDeck {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_UPCOMING_INVESTMENTS_QUERY = gql`
  ${getUpcomingInvestmentFragment}
  query getUpcomingInvestments($filters: UpcomingInvestmentFiltersInput) {
    upcomingInvestments(filters: $filters) {
      data {
        id
        attributes {
          ...GetUpcomingInvestmentFields
        }
      }
    }
  }
`;
