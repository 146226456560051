import { createGlobalStyle } from 'styled-components';

import theme from 'styles/theme';

export default createGlobalStyle`
  html,
  body {
    font-family: 'NotoSans';
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
  }
    
  body {
    overflow-x: hidden;
    margin: 0;

    strong {
      font-weight: 700;
    }

    em {
      font-style: italic;
    }
  }

  h1, h2, h3, h4, h5 {
    margin-top: 0;
    font-family: 'Gilroy' !important;

    em {
      font-style: italic;
    }
  }

  * {
    box-sizing: border-box;
  }

  em-emoji-picker {
    height: 255px;
  }

  .ElementsApp.is-autofilled .InputElement:not(.is-empty),
  .ElementsApp.is-autofilled .InputElement:-webkit-autofill {
    color: ${theme.colors.primaryDeep};
  }

  .react-pdf__Page {
    .linkAnnotation {
      position: absolute;
    }

    .linkAnnotation a {
        display: flex;
        width: 100%;
        height: 100%;
    }
  }

  .react-pdf__Page {
    max-width: 100%;
  
    &.prevPage {
      position: absolute !important;
      z-index: 1;
    }
  }

  .CollapsePanel-extraSpacing {
    &.fullbleed {
      .ant-collapse-content-box  {
        padding: 0 !important;
      }
    }

    & > .ant-collapse-header {
      padding: 20px !important;

      & > .ant-collapse-arrow {
        right: 20px !important;
        top: 14px !important;
      }
    }

    .ant-collapse-content {
      overflow: hidden;
    }
  }

  .PitchDeck-UploadDragger .ant-upload-list {
    margin: 5px 20px 10px;
  }

  .ant-checkbox,
  .ant-radio {
    &&& {
      align-self: flex-start;
      margin-top: 3px;
    }
  }

  .ant-modal-header {
    &&& {
      padding: 30px 40px;
    }
  }


  .ant-modal-body {
    &&& {
      padding: 20px 40px 30px;
    }
  }

  .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
      text-align: left;
  }

  .ant-table-wrapper .ant-table-thead >tr >th,
  .ant-table-wrapper .ant-table-thead >tr >td {
    font-weight: 700 !important;
    font-family: 'Gilroy';
  }

  /* .ant-input-textarea-show-count::after {
    font-size: 16px;
    font-weight: 300;
    margin-top: 5px;
  } */

  .ant-modal .ant-modal-close {
    &&& {
      top: 32px;
      right: 40px;
    }
  }

  .ant-badge-multiple-words {
    &&& {
      padding: 0 4px;
    }
  }

  .ant-table-column-title {
    &&& {
      text-align: left;
    }
  }

  .ant-modal-mask {
    &&& {
      /* background-color: rgba(0, 0, 0, 0.7); */
    }
  }

  .ant-modal-content {
    &&& {
      overflow: hidden;
    }
  }

  .ant-typography {
    &&& {
      white-space: pre-line;
    }
  }
  
  /* .ant-select-item-option {
    align-items: center;
  } */

  .Header-MobilePopover {
    text-align: right;
  }

  .Notifications-Popover,
  .Header-MobilePopover {
    .ant-popover-arrow {
      display: none;
    }
  }

  .Notifications-Popover {
    .ant-popover-inner-content {
      padding: 0;
      max-height: 90vh;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  /* .ant-collapse-content > .ant-collapse-content-box {
    padding: 20px 30px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 16px;
  } */

  .force-required::after,
  .ant-form-item-required::before {
    display: none !important;
  }

  .Hidden-form-validation {
    .ant-form-item-control-input {
      min-height: 0;
    }
  }

  .ant-typography ul {
    list-style-type: disc;
  }

  .ant-input-textarea-show-count {
    position: relative;

    &:after {
      position: absolute;
      top: 100%;
      right: 0;
    }
  }

  .ProseMirror {
    border: 1px solid #f0f0f0;
    background-color: white;
    border-radius: 6px;
    padding: 10px 15px;
    margin-top: 6px;
    min-height: 75px;
    cursor: text;

    .mention {
      color: ${theme.colors.primary};
      background-color: ${theme.colors.primarySofter};
      border-radius: 4px;
      box-decoration-break: clone;
      padding: 1px 5px;
      white-space: nowrap;
    }

    &:focus {
      outline: none !important;
      border: 1px solid ${theme.colors.primary};
      box-shadow: 0 0 10px ${theme.colors.primarySoft};
    }

    >*+* {
        margin-top: 0.75em;
    }

    p {
      margin: 0;
    }

    a {
      text-decoration: underline;
    }

    ul,
    ol {
        padding: 0 1rem;
        margin: 5px 0;
    }
  }

  .Project-DetailsText {
    p {
      margin: 0;
      min-height: 26px;
    }

    a {
      text-decoration: underline;
    }

    ul,
    ol {
        padding: 0 1rem;
        margin: 5px 0;
    }
  }
`;
