import { gql } from '@apollo/client';

export const sarFragment = gql`
  fragment SARFields on SuspiciousActivityReport {
    target
    reportedBy {
      data {
        id
        attributes {
          accountDetails {
            firstName
            lastName
          }
          avatar {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
    descriptionOfActivity
    reasonForSuspicion
    status
    actionTaken
    notes {
      content
      author {
        data {
          id
          attributes {
            accountDetails {
              firstName
              lastName
            }
            avatar {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
      createdOn
      lastStatus
      newStatus
    }
  }
`;

export const GET_SAR_QUERY = gql`
  ${sarFragment}
  query getSuspiciousActivityReport($id: ID!) {
    suspiciousActivityReport(id: $id) {
      data {
        id
        attributes {
          ...SARFields
        }
      }
    }
  }
`;

export const CREATE_SAR_QUERY = gql`
  ${sarFragment}
  mutation createSuspiciousActivityReport(
    $target: String!
    $reportedBy: ID!
    $descriptionOfActivity: String!
    $reasonForSuspicion: String!
    $actionTaken: String
  ) {
    createSuspiciousActivityReport(
      data: {
        target: $target
        reportedBy: $reportedBy
        descriptionOfActivity: $descriptionOfActivity
        reasonForSuspicion: $reasonForSuspicion
        actionTaken: $actionTaken
      }
    ) {
      data {
        id
        attributes {
          ...SARFields
        }
      }
    }
  }
`;

export const UPDATE_SAR_QUERY = gql`
  ${sarFragment}
  mutation updateSuspiciousActivityReport(
    $id: ID!
    $status: ENUM_SUSPICIOUS_ACTIVITY_REPORT_STATUS_INPUT
    $actionTaken: String
    $notes: ComponentSuspiciousActivityReportNotesInput
  ) {
    updateSuspiciousActivityReport(
      id: $id
      data: { status: $status, actionTaken: $actionTaken, notes: $notes }
    ) {
      data {
        id
        attributes {
          ...SARFields
        }
      }
    }
  }
`;
